import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Col, Divider, Input, Row, Space, notification, Table, Tooltip } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { getAllCPMMapping, setCPMMapping, deleteCPMMapping, updateCPMMapping } from "../../api/services/CPMMapping";
import { NotificationType } from "../../utils/notifictions";
import "react-alice-carousel/lib/alice-carousel.css";
import PureCard from "../../components/PureCard/PureCard";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import DomainSelect from "../DomainSelection/DomainSelect";
import CPMMappingModal from "../../modals/CPMMappingModal";
import { CPMMapping as CPMMappingType } from "../../utils/types";
import { EditIcon } from "../../assets/images/edit";
import { DeleteIcon } from "../../assets/images/delete";
import { COLORS } from "../../utils/colors";
import { Modal } from 'antd';

const CPMMapping = () => {
  const [filters, setFilters] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<string>("");
  const [showAddCPMMappingModal, setShowAddCPMMappingModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<CPMMappingType>({} as CPMMappingType);
  const permissions = useSelector((state: AppState) => state.permissions); 
  const [api, contextHolder] = notification.useNotification();
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getData();
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const onFinish = async (data: CPMMappingType) => {
    setShowAddCPMMappingModal(false);
    setIsLoading(true);
    let response = await setCPMMapping(data);
    if (response.status && response.status === "CPM Mapping exist") {
      openNotificationWithIcon(
        "warning",
        "You've already inserted this CPM Mapping"
      );
    } else {
      openNotificationWithIcon("success", "CPM Mapping added successfully");
      await getData();
    }
    setIsLoading(false);
  };

  const onFinishEdit = async (data: CPMMappingType) => {
    setShowEditModal(false);
    setIsLoading(true);
    await updateCPMMapping(data);
    openNotificationWithIcon("success", "CPM Mapping updated successfully");
    await getData();
    setIsLoading(false);
  };

  const handleDelete = async (record: any) => {
    try {
      setIsLoading(true);
      await deleteCPMMapping(record);
      openNotificationWithIcon("success", "CPM Mapping deleted successfully");
      await getData();
    } catch (error) {
      openNotificationWithIcon("error", "Failed to delete CPM Mapping");
    } finally {
      setIsLoading(false);
    }
  };

  const showDeleteConfirm = (record: any) => {
    Modal.confirm({
      title: 'Delete',
      content: 'Are you sure you want to delete the CPM Mapping?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => handleDelete(record),
    });
  };

  const getColumns = () => {
    return [
      {
        title: "Id",
        key: "id",
        dataIndex: "id",
      },
      {
        title: "Publisher Key",
        key: "pub_name",
        dataIndex: "pub_name",
      },
      {
        title: "Domain Id",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "CPM",
        key: "cpm",
        dataIndex: "cpm",
      },
      {
        title: "Start Date",
        key: "start_date",
        dataIndex: "start_date",
      },
      {
        title: "End Date",
        key: "end_date",
        dataIndex: "end_date",
      },
      {
        title: "Operation",
        key: "operation",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <Row style={{ justifyContent: "center" }}>
            <Space>
              <Button
                permission="update:CPMMapping"
                type="default"
                onClick={() => {
                  setSelectedRecord(record);
                  setShowEditModal(true);
                }}
                icon={
                  <Tooltip
                    placement="topLeft"
                    title="Edit"
                    overlayStyle={{ maxWidth: "400px" }}
                  >
                    <span className="icon">
                      <EditIcon
                        color={
                          isDarkMode
                            ? COLORS.darkMode.main
                            : COLORS.lightMode.buttons
                        }
                      />
                    </span>
                  </Tooltip>
                }
              />
  
              <Button
                permission="delete:CPMMapping"
                type="default"
                onClick={() => showDeleteConfirm(record)}
                icon={
                  <Tooltip
                    placement="topLeft"
                    title="Delete"
                    overlayStyle={{ maxWidth: "400px" }}
                  >
                    <span className="icon">
                      <DeleteIcon
                        color={
                          isDarkMode
                            ? COLORS.darkMode.main
                            : COLORS.lightMode.buttons
                        }
                      />
                    </span>
                  </Tooltip>
                }
              />
            </Space>
          </Row>
        ),
      }
    ];
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await getAllCPMMapping();
      setData(data);
    } catch (error) {
      openNotificationWithIcon("error", "Failed to fetch CPM Mapping");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row className="hide-unit">
      <Row justify="space-between" className="full-width">
        <Col className="title">
          <div style={{ color: isDarkMode ? "#FDB813" : "#4097FF" }}>
          CPM Mapping
          </div>
        </Col>
        <Col className="buttons">
          <Button
            type="primary"
            title="Add CPM Mapping"
            onClick={() => setShowAddCPMMappingModal(true)}
          />
        </Col>
      </Row>
      <Row
        gutter={[20, 0]}
        style={{
          width: "100%"
        }}
      >
          <Col span={5}>
            <PublisherSelect
              allowClear
              mode="new"
              onSelect={(pub_id) => {
                if (pub_id) {
                  setFilters({ ...filters, pub_id });
                } else {
                  const obj = { ...filters };
                  delete obj.pub_id;
                  setFilters(obj);
                }
              }}
            />
          </Col>
        <Col span={5}>
          <DomainSelect
            allowClear
            onSelect={(value: any) => {
              setFilters({ ...filters, domain_id: value });
            }}
          />
        </Col>
      </Row>

      <Divider />

      {data && (
        <Row className="full-width">
          <PureCard>
            <Table
              rowKey={(record) => record.id}
              pagination={false}
              columns={getColumns()}
              dataSource={data.filter((record: any) => {
                for (const key in filters) {
                  if (
                    filters[key] &&
                    filters[key] !== "" &&
                    filters[key].toString() !== record[key]?.toString()
                  ) return false;
                }
                return true;
              })}
              loading={isLoading}
              bordered
            />
          </PureCard>
        </Row>
      )}

      {showAddCPMMappingModal && (
        <CPMMappingModal
          close={() => setShowAddCPMMappingModal(false)}
          onFinish={onFinish}
          mode="new"
        />
      )}

      {showEditModal && (
        <CPMMappingModal
          close={() => setShowEditModal(false)}
          onFinish={onFinishEdit}
          mode="edit"
          data={selectedRecord}
        />
      )}

      {contextHolder}
    </Row>
  );
};

export default CPMMapping;