import Modal from "../components/Modal/Modal";
import { Form, Input, Row, Col } from "antd";

import Select from "../components/Select/Select";
import Button from "../components/Button";

import { Mode, ProviderDetailes } from "../utils/types";
import { IDS } from "../utils/elementsIds";

import { emailValidator } from "../utils/utils";

interface AdvertisersModalProps {
  close: () => void;
  data?: ProviderDetailes;
  onFinish: (provider: ProviderDetailes) => void;
  mode: Mode;
}

const ProviderModal = (props: AdvertisersModalProps) => {
  const { data, close, onFinish, mode } = props;
  const [form] = Form.useForm();
  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Provider" : "Edit Provider"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      removeCloseButton={true}
    >
      <Form
        form={form}
        name="basic"
        onFinish={async (provider: ProviderDetailes) => {
          if (mode === "edit" && data)
          {
            provider.provider_id = data?.provider_id;
          }
            onFinish(provider);
          }
        }
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
        requiredMark
        {...(data && { initialValues: data })}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Provider Name",
                },
              ]}
            >
              <Input placeholder="Type Provider Name" size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Contact Name"
              name="contact_name"
              rules={[
                {
                  required: true,
                  message: "Type Contact Name",
                },
              ]}
            >
              <Input placeholder="Type Contact Name" size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Contact Email"
              name="contact_email"
              rules={[
                {
                  required: true,
                  message: "Choose Contact Email",
                },
                { validator: emailValidator },
              ]}
            >
              <Select
                allowClear
                placeholder="Type emails"
                options={[]}
                mode="tags"
                showArrow={false}
                dropdownStyle={{ display: "none" }}
                open={false}
              ></Select>
            </Form.Item>
          </Col>

        </Row>
        <Row style={{ justifyContent: "center", gap: "16px" }}>
          <Button type="default" title="Cancel" onClick={close}></Button>
          <Button
            id={IDS.PROVIDERS.ADD_BUTTON}
            type="primary"
            title={mode === "new" ? "Add Provider" : "Save changes"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProviderModal;
