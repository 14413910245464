import Modal from "../components/Modal/Modal";
import { Col, Form, Input, Row, Select  } from "antd";
import Button from "../components/Button";
import { useState } from "react";
import { Mode } from "../utils/types";
import { CPMMapping } from "../utils/types";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import { IDS } from "../utils/elementsIds";

interface CPMMappingModalProps {
  close: () => void;
  onFinish: (data: any) => void;
  mode: Mode;
  data?: CPMMapping;
}

const CPMMappingModal = (props: CPMMappingModalProps) => {
  const { close, onFinish, mode, data } = props;
  const [dataCPMMapping, setDataCPMMapping] = useState<CPMMapping>(data ? {...data } : {} as CPMMapping);
  const initialValues = {
    cpm: dataCPMMapping.cpm ?? "",
    start_date: dataCPMMapping.start_date ?? "",
    end_date: dataCPMMapping.end_date ?? "",
  };
  let final = {};
  if(mode === "new") final = dataCPMMapping; 
  else final = { ...dataCPMMapping };

  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Social Feed Mapping" : "Edit Social Feed Mapping"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form name="basic" autoComplete="off" layout="vertical" requiredMark onFinish={() => onFinish(final)} initialValues={initialValues} style={{ overflow: "hidden" }}>
      {mode === "new" && (<>
      <Row gutter={[24, 24]}>
       <Col span={8}>
            <Form.Item
              label="Choose Publisher"
              name="publisher"
              rules={[
                {
                  required: !dataCPMMapping.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                id={IDS.NEW_TAG.PUBLISHER_SELECT}
                mode="new"
                onSelect={(pub_id, pub_key) => {
                  setDataCPMMapping({
                    ...dataCPMMapping,
                    pub_id: pub_id,
                    pub_key: pub_key
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Choose Domain"
              name="domain"
              rules={[
                {
                  required: !dataCPMMapping.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                id={IDS.NEW_TAG.DOMAIN_SELECT}
                onSelect={(value: any) => {
                  setDataCPMMapping({
                    ...dataCPMMapping,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
       </Row>
       </>
        )}
        <Row gutter={[24, 24]}>
          {(<>
          <Col span={8}>
            <Form.Item
              label="CPM"
              name="cpm"
              rules={[
                {
                  required: true,
                  message: "Please type CPM",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Type CPM"
                value={dataCPMMapping.cpm}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      cpm: Number(e.target.value),
                    });
                  else
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      cpm: 0,
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          </>
        )}
        </Row>
        <Row gutter={[24, 24]}>
          {(<>
          <Col span={8}>
            <Form.Item
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please type Start Date",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Type Start Date"
                value={dataCPMMapping.start_date}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      start_date: e.target.value,
                    });
                  else
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      start_date: "",
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="End Date"
              name="end_date"
            >
              <Input
                size="large"
                placeholder="Type End Date"
                value={dataCPMMapping.end_date}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      end_date: e.target.value,
                    });
                  else
                    setDataCPMMapping({
                      ...dataCPMMapping,
                      end_date: "",
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          </>
        )}
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            title={mode === "new" ? "Add CPM Mapping" : "Edit CPM Mapping"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default CPMMappingModal;
