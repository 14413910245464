import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  Input,
  Tooltip,
  Popconfirm,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import PureCard from "../../components/PureCard/PureCard";

import { ProviderDetailes } from "../../utils/types";
import { SearchOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { EditIcon } from "../../assets/images/edit";
import { DeleteIcon } from "../../assets/images/delete";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { COLORS } from "../../utils/colors.js";
import { NotificationType } from "../../utils/notifictions";
import { useAuth0 } from "@auth0/auth0-react";

import "./styles.scss";
import {
  addProvider,
  deleteProvider,
  getWithAggregator,
  updateProvider,
} from "../../api/services/Providers";
import ProviderModal from "../../modals/ProviderModal";

const Providers = () => {
  const [data, setData] = useState<ProviderDetailes[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [api, contextHolder] = notification.useNotification();
  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const { user } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useState<ProviderDetailes>(
    {} as ProviderDetailes
  );
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let data = await getWithAggregator();
    setData(data);
    setLoading(false);
  };

  const columns = [
    {
      title: "Provider Id",
      dataIndex: "provider_id",
      width: '10%',
    },
    {
      title: "Provider Name",
      dataIndex: "name",
      width: '20%',
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      width: '20%',
    },
    {
      title: "Contact Email",
      dataIndex: "contact_email",
      width: '30%',
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "15%",
      render: (_: any, record: ProviderDetailes) => (
        <Row
          style={{
            justifyContent: "space-evenly",
            width: "100%",
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            permission="update:providers"
            type="default"
            onClick={() => {
              setShowEditModal(true);
              setSelectedRecord(record);
            }}
            icon={
              <Tooltip
                placement="topLeft"
                title={"Edit"}
                overlayStyle={{ maxWidth: "400px" }}
              >
                <span className="icon">
                  <EditIcon
                    color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                  />
                </span>
              </Tooltip>
            }
          ></Button>

          <Popconfirm
            title="Delete"
            style={{ background: "red" }}
            description="Are you sure you want to delete the provider?"
            placement="topLeft"
            onConfirm={async () => {
              await deleteProvider(record.provider_id);
              openNotificationWithIcon(
                "success",
                "Provider deleted successfully"
              );
              await getData();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              permission="delete:providers"
              type="default"
              onClick={() => {}}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Delete"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <DeleteIcon
                      color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                    />
                  </span>
                </Tooltip>
              }
            ></Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const close = () => {
    setShowNewModal(false);
    setShowEditModal(false);
  };
  const onFinishAddNew = async (provider: ProviderDetailes) => {
    close();
    setLoading(true);
    provider.modified_by = user?.email || "";
    let response = await addProvider(provider);
    if (response.status && response.status === "exsist") {
      openNotificationWithIcon(
        "warning",
        `You've already created provider with name: ${provider.name}`
      );
      setLoading(false);
    } else {
      close();
      openNotificationWithIcon("success", "Provider added successfully");
      await getData();
      setLoading(false);
    }
  };

  const onFinishEdit = async (provider: ProviderDetailes) => {
    close();
    setLoading(true);
    provider.modified_by = user?.email || "";
    let response = await updateProvider(provider);
    if (response && response.status && response.status === "exsist") {
      openNotificationWithIcon(
        "warning",
        `You've already created provider with name: ${provider.name}`
      );
      setLoading(false);
    } else {
      close();
      openNotificationWithIcon("success", "Provider updated successfully");
      await getData();
      setLoading(false);
    }
  };

  const manipulateData = (provider: ProviderDetailes) => {
    let obj = { ...provider };
    if (provider.contact_email)
      obj.contact_email = provider.contact_email.split(",");
    return obj;
  };

  return (
    <PureCard>
      <div className="providers">
        {contextHolder}
        <Row className="top-con">
          <div style={{ width: "50%" }}>
            <Input
              addonBefore={<SearchOutlined />}
              size="large"
              style={{ marginBottom: 24, width: "60%" }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          <Button
            title="Add New Provider"
            type="primary"
            size="large"
            onClick={() => {
              setShowNewModal(true);
            }}
            icon={<PlusCircleOutlined />}
          />
        </Row>
        <Table
          bordered
          dataSource={
            data &&
            data.filter((record: ProviderDetailes) => {
              return record.name
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            })
          }
          rowKey={"provider_id"}
          columns={columns}
          loading={loading}
          tableLayout="fixed"
          style={{ width: '100%' }} 
        />

        {showNewModal && (
          <ProviderModal close={close} onFinish={onFinishAddNew} mode="new" />
        )}
        {showEditModal && (
          <ProviderModal
            mode="edit"
            close={close}
            onFinish={onFinishEdit}
            data={manipulateData(selectedRecord)}
          />
        )}
      </div>
    </PureCard>
  );
};

export default Providers;
