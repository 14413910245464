import client from "../client";
import { CPMMapping  } from "../../utils/types";

export const deleteCPMMapping = async (selectedItem: any[]) => {
  const res = await client.delete(`cpm-mapping/delete_cpm_mapping`, {
    data: selectedItem,
  });
  return res.data;
};

export const setCPMMapping  = async (data: CPMMapping ) => {
  const res = await client.post(`cpm-mapping/create_cpm_mapping`, data);
  return res.data;
};

export const updateCPMMapping  = async (data: CPMMapping ) => {
  const res = await client.post(`cpm-mapping/update_cpm_mapping`, data);
  return res.data;
};

export const getAllCPMMapping  = async () => {
  const res = await client.get(`cpm-mapping/get_all_cpm_mapping`);
  return res.data;
};